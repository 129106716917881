import { Injectable } from '@angular/core';
import * as mapTypes from '../../models/map';
import MarkerWithLabel from '@google/markerwithlabel';
import { Observable } from 'rxjs/internal/Observable';
import { Observer } from 'rxjs/internal/types';

@Injectable({
    providedIn: 'root'
})

export class MapService {
    private map: Promise<google.maps.Map>;
    private mapResolver: (valuie?: google.maps.Map) => void;

    constructor() {}

    init() {
        this.map = new Promise<google.maps.Map>((resolve: () => void) => { this.mapResolver = resolve; });
    }

    createMap(el: HTMLElement, mapOptions: mapTypes.MapOptions): any {
        const map = new google.maps.Map(el, mapOptions);
        this.mapResolver(map as google.maps.Map);
        return;
    }

    setCenter(latLng: google.maps.LatLngLiteral): void {
        if (this.map) {
            this.map.then( (m: google.maps.Map) => { m.setCenter(latLng); });
        }
    }

    createMarker(options: mapTypes.MarkerOptions = {} as mapTypes.MarkerOptions, addToMap: boolean = true): Promise<mapTypes.Marker> {
        return this.map.then((map: google.maps.Map) => {
            if (addToMap) {
                options.map = map;
            }
            return new MarkerWithLabel(options);
        });
    }

    createInfoWindow(options: any, addToMap = true): Promise<google.maps.InfoWindow> {
        return this.map.then((map: google.maps.Map) => {
            if (addToMap) {
                options.map = map;
            }
            return new google.maps.InfoWindow(options);
        });
    }

    getZoom(): Promise<number> {
        return this.map.then( (map: google.maps.Map) => map.getZoom() );
    }

    setZoom(zoom: number): Promise<void> {
        return this.map.then( (map: google.maps.Map) => map.setZoom(zoom) );
    }

    getCenter(): Promise<google.maps.LatLng> {
        return this.map.then( (map: google.maps.Map) => map.getCenter() );
    }

    createEventObservable<E>(eventName: string): Observable<E> {
        return new Observable( (observer: Observer<E>) => {
            this.map.then( (m: google.maps.Map) => {
                m.addListener(eventName, (arg: E) => {
                    observer.next(arg);
                });
            });
        });
    }
}
