import { Injectable } from '@angular/core';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { isEmpty } from 'src/app/helper/utils';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(
    private iziToast: Ng2IzitoastService,
    private translocoService: TranslocoService
  ) { }

  success(msg = '', title = '') {
    this.iziToast.success({
      title: !isEmpty(title) ? this.translocoService.translate(title) : '',
      message: !isEmpty(msg) ? this.translocoService.translate(msg) : '',
      position: 'topRight',
      transitionIn: 'bounceInDown',
      transitionOut: 'fadeOutRight'
    });
  }

  info(msg = '', title = '') {
    this.iziToast.info({
      title: !isEmpty(title) ? this.translocoService.translate(title) : '',
      message: !isEmpty(msg) ? this.translocoService.translate(msg) : '',
      position: 'topRight',
      transitionIn: 'bounceInDown',
      transitionOut: 'fadeOutRight'
    });
  }

  danger(msg = '', title = 'ERROR') {
    this.iziToast.error({
      title: !isEmpty(title) ? this.translocoService.translate(title) : '',
      message: !isEmpty(msg) ? this.translocoService.translate(msg) : '',
      position: 'topRight',
      transitionIn: 'bounceInDown',
      transitionOut: 'fadeOutRight'
    });
  }
}
