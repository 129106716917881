import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormArray, Validators } from '@angular/forms';
import { FormGroupFilters } from '../shared/modules/table/models/table-filter.model';

export function getClassToCropImage(url: string) {
  const image = new Image();
  image.src = url;
  return (image.height > image.width) ? 'landscape' : 'portrait';
}

export function generateParamsForRoutes(filters: any, page: number = null, pageSize: number = null) {
    const params: any = {};
    if (!isNull(page)) { params.page = page; }
    if (!isNull(pageSize)) { params.pageSize = pageSize; }
    if (filters) {
        // tslint:disable-next-line:forin
      for (const key in filters) {
        params[key] = filters[key];
      }
    }

    return params;
}

export function addOrderToFilters(filters, columnSettings) {
    const column = columnSettings.find(item => item.isActive === true);
    if (!isNullOrEmptyOrUndefined(column) && !isNullOrEmptyOrUndefined(filters)) {
        const sortKey = column.sortKey.split(',');
        if (sortKey.length > 1) {
            filters['order'] = (column.sortAsc) ? sortKey.join(',ASC,') : sortKey.join(',DESC,');
        } else {
            filters['order'] = (column.sortAsc) ? sortKey[0] + ',ASC' : sortKey[0] + ',DESC';
        }
    }
    return filters;
}

export function isEmpty(field) {
    let valid = true;
    // Objectd
    if (typeof field === 'object') {
        for (const key in field) {
            if (field.hasOwnProperty(key)) {
                valid = false;
            }
        }
    } else if (field !== '') {
        valid = false;
    }

    // Array
    if (Array.isArray(field)) {
        if (field.length > 0) {
            valid = false;
        }
    }

    return valid;
}

export function toModelDate(date: NgbDateStruct): Date {
    return date ? new Date(date.year + '-' + date.month + '-' + date.day) : null;
}

export function isNullOrEmptyOrUndefined(field) {
    let valid = false;
    if (field === '' || field === null || field === undefined) {
        valid = true;
    }
    return valid;
}

export function isUndefined(field) {
    return field === undefined;
}

export function isNull(field) {
    return field === null;
}

export function generateOptionsByObject(obj: object, value: string, label: string) {
    const options: any = [];
    Object.keys(obj).forEach( key => {
        options.push({
            value: obj[key][value],
            label: obj[key][label]
        });
    });
    return options;
}

export function removeValidators(form: FormGroup, field) {
  form.get(field).clearValidators();
  form.get(field).updateValueAndValidity();
}

export function getFormArray(field: string, form: FormGroup) {
    return (form.get(field) as FormArray);
}

export function getArrayByObjectKey(arr: any[], key) {
    const items = [];
    arr.forEach( item => {
        items.push(item[key]);
    });
    return items;
}

export function exportCSV(data: string, name: string) {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = name + '.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
}

export function addFiltersTable(tableFilters: FormGroupFilters[], name: string, options: object[], type, placeholder: string, labelBind: string, valueBind: string, defaultValue?: string, validators?: Validators[]) {
    const index = tableFilters.findIndex(filter => filter.name === name);
    tableFilters.splice((index > -1) ? index : tableFilters.length, (index > -1) ? 1 : 0, {
      name,
      placeholder,
      type,
      options,
      labelBind,
      valueBind,
      defaultValue,
      validators
    });
    return tableFilters = Object.assign([], tableFilters);
}

export function getOriginUrl() {
    return window.location.origin;
}
