import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { Admin } from 'src/app/models/admin';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavbarComponent implements OnInit {
  admin: Admin;

  constructor(private authService: AuthService) { }

  ngOnInit() {
    this.getCurrentAdmin();
  }

  getCurrentAdmin() {
    this.admin = this.authService.getCurrentAdmin();
  }

  logout() {
    this.authService.logout();
  }
}
