export const EndpointsConstants = {
  requestLimit: 20,
  shortRequestLimit: 12,
  admin: {
    base: '/admin',
    me: '/me'
  },
  auth: {
    base: '/auth',
    admin: '/admin',
    login: '/login',
    passwordRecovery: '/password-recovery',
    passwordUpdate: '/password-update'
  },
  city: {
    base: '/city'
  },
  client: {
    base: '/client'
  },
  clientEndpoint: {
    base: '/client-endpoint'
  },
  country: {
    base: '/country'
  },
  prediction: {
    base: '/gea'
  },
  predictor: {
    base: '/predictor'
  },
  province: {
    base: '/province'
  },
  setting: {
    base: '/setting'
  },
  variable: {
    base: '/variable'
  }
};
