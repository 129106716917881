// Modules
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
// Translation
import { HttpClientModule } from '@angular/common/http';
// Components
import { AppComponent } from './app.component';
// Interceptors
import { RequestInterceptor } from './http-interceptors/http-interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
// Services
import { AuthGuard } from './core/services/auth-guard/auth-guard.service';

import { registerLocaleData } from '@angular/common';
import localeEsAr from '@angular/common/locales/es-AR';
import { TranslocoRootModule } from './shared/modules/transloco/transloco-root.module';
import { SharedModule } from './shared/shared.module';
registerLocaleData(localeEsAr);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    CoreModule,
    SharedModule,
    TranslocoRootModule,
  ],
  providers: [
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'es-AR' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
