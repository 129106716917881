import { isUndefined } from 'util';

export interface IParser<T> {
    parse(e: any): T;
    parseMultiple(es: any[]): T[];
}

export abstract class BaseEntity<T> implements IParser<T> {

    protected id?: number | string;
    protected isDeleted?: boolean;
    protected createdAt?: Date;
    protected updatedAt?: Date;

    constructor(e?: any) {
        if (!isUndefined(e)) { this.populate(e); }
    }

    public abstract parse(e: any): T;

    public parseMultiple(es: any[]): T[] {
        const u = [];
        for (const e of es) {
            u.push(this.parse(e));
        }
        return u;
    }

    protected populate(e: any): void {
      this.id = e.id;
      this.isDeleted = e.isDeleted;
      this.createdAt = new Date(e.createdAt);
      this.updatedAt = new Date(e.updatedAt);
    }
}

