import { finalize, tap } from 'rxjs/operators';
import { HttpInterceptor, HttpRequest, HttpResponse, HttpHandler, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoaderService } from 'src/app/core/services/loader/loader.service';
import { Router } from '@angular/router';
import { AuthService } from '../core/services/auth/auth.service';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

    constructor(
        private router: Router,
        private loaderService: LoaderService,
        private authService: AuthService,
        private translocoService: TranslocoService,
        private iziToast: Ng2IzitoastService,
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        let ok: string;
        const token = JSON.parse(localStorage.getItem('balanceo-admin-token')) || '';

        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: req.headers.get('Authorization') || token
        });

        const foreignUrl = req.url.indexOf('upload') > -1;

        if (foreignUrl) {
            headers = new HttpHeaders({
                Authorization: token ? token : ''
            });
        }

        const clonedReq = req.clone({ headers });
        return next.handle(clonedReq)
            .pipe(
                tap(
                    event => { ok = event instanceof HttpResponse ? 'succeeded' : ''; },
                    error => { ok = error, this.onSubscribeError(ok); }
                    // Log when response observable either completes or errors
                ),
                finalize(() => {
                    this.loaderService.hide();
                })
            );
    }

    private onSubscribeError(error: any) {
        if (error.status === 401 && error.error.message === 'InvalidToken') {
            this.authService.logout();
            this.router.navigate(['/login']);
            return false;
        } else {
            this.iziToast.error({
                title: this.translocoService.translate('httpInterceptor.error'),
                message: error.error.message,
                position: 'topRight',
                transitionIn: 'bounceInDown',
                transitionOut: 'fadeOutRight'
            });
            this.loaderService.forceLoader(false);
        }
    }
}
