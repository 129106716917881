import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
    {
        path: '/balancing/view', title: 'Balanceo', icon: 'fas fa-balance-scale', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: []
    },
    {
        path: '/admin/list', title: 'modules.shared.sidebar.administrators', icon: 'fas fa-user-tie', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: []
    },
    {
        path: '/client/list', title: 'modules.shared.sidebar.clients', icon: 'fas fa-user', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: []
    },
    {
        path: '/client-endpoint/list', title: 'modules.shared.sidebar.endpoints', icon: 'fas fa-globe-americas', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: []
    },
    {
        path: '', title: 'modules.shared.sidebar.territory.title', icon: 'fas fa-map-marked-alt', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
            { path: '/country/list', title: 'modules.shared.sidebar.territory.country', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/province/list', title: 'modules.shared.sidebar.territory.province', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/city/list', title: 'modules.shared.sidebar.territory.city', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
        ]
    },
    {
        path: '/predictor/list', title: 'modules.shared.sidebar.predictors', icon: 'fas fa-brain', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: []
    },
    {
        path: '/variable/list', title: 'modules.shared.sidebar.variables', icon: 'fas fa-dice-d20', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: []
    },
    {
        path: '/settings/edit', title: 'modules.shared.sidebar.settings', icon: 'fas fa-cogs', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: []
    }
];
