import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-map-info-window',
  templateUrl: './map-info-window.component.html',
  styleUrls: ['./map-info-window.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapInfoWindowComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }
}
