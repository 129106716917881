import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { LoaderService } from 'src/app/core/services/loader/loader.service';

@Component({
  selector: 'app-web-layout',
  templateUrl: './web-layout.component.html',
  styleUrls: ['./web-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WebLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
