// Modules
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgSelectModule } from '@ng-select/ng-select';
// Components
import { FooterComponent } from './components/footer/footer.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { InputDateTimeComponent } from './components/input-date-time/input-date-time.component';
import { SelectSearchComponent } from './components/select-search/select-search.component';
import { MapModule } from './modules/map/map.module';
import { InfoTooltipComponent } from './components/tooltip/info-tooltip.component';
import { TranslocoModule } from '@ngneat/transloco';
import { LoaderComponent } from './components/loader/loader.component';

const importExport = [
  NgbModule,
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  HttpClientModule,
  TranslocoModule,
  NgSelectModule,
  MapModule
];

const declarationExport = [
  FooterComponent,
  SidebarComponent,
  NavbarComponent,
  PaginationComponent,
  InputDateTimeComponent,
  SelectSearchComponent,
  InfoTooltipComponent,
  LoaderComponent
];

@NgModule({
  declarations: [
    ...declarationExport
  ],
  imports: [
    SweetAlert2Module.forRoot(),
    ...importExport
  ],
  exports: [
    SweetAlert2Module,
    ...declarationExport,
    ...importExport
  ]
})
export class SharedModule { }
