import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Input } from '@angular/core';
import { LoaderService } from 'src/app/core/services/loader/loader.service';
import { enterAnimation } from 'src/app/shared/animations/animations';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  animations: [
    enterAnimation
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderComponent implements OnInit {
  show: boolean;
  @Input() forceLoader: boolean;
  @Input() class: string;

  constructor(
    private cdr: ChangeDetectorRef,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    this.loaderService.loaderState.subscribe(
      isShowing => {
        this.show = isShowing;
        this.cdr.detectChanges();
      }
    );
    this.loaderService.forceLoaderState.subscribe(
      isShowing => {
        this.forceLoader = isShowing;
        this.cdr.detectChanges();
      }
    );
  }
}
