import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../http/http.service';
import { EndpointsConstants } from 'src/app/helper/endpoints';
import { setLocalData, removeLocalData, getLocalData } from 'src/app/helper/storage';
import { Router } from '@angular/router';
import { ToastService } from '../toast/toast.service';
import { isNull } from 'util';
import { Admin } from 'src/app/models/admin';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private router: Router,
    private httpService: HttpService,
    private toast: ToastService
  ) { }

  login(email: string, password: string): Observable<boolean> {
    const url = `${EndpointsConstants.auth.base}${EndpointsConstants.auth.admin}${EndpointsConstants.auth.login}`;

    return new Observable<boolean>(observer => {
      this.httpService.post(url, { email, password }, true).subscribe(
        response => {
          this.setLoginData(response);
          observer.next(true);
        });
    });
  }

  setLoginData(response = null) {
    const token = response.data.token;
    const admin = new Admin(response.data.admin);

    this.setCurrentAdmin(admin);
    setLocalData('balanceo-admin-token', token);

    this.toast.success('', 'services.auth.welcome');

    this.router.navigate(['/admin', 'list']);
  }

  logout(): void {
    removeLocalData('balanceo-admin-currentAdmin');
    removeLocalData('balanceo-admin-token');
    this.router.navigate(['/auth', 'login']);
  }

  isAuthenticated(redirect: boolean = true): boolean {
    const currentAdmin = getLocalData('balanceo-admin-currentAdmin');
    if (!currentAdmin) {
      if (redirect) {
        this.router.navigate(['/auth', 'login']);
      }
      return false;
    }
    return true;
  }

  forgotPassword(email: string) {
    const url = `${EndpointsConstants.auth.base}${EndpointsConstants.auth.admin}${EndpointsConstants.auth.passwordRecovery}`;
    return this.httpService.post(url, { email });
  }

  restorePassword(password: string, token: string) {
    const url = `${EndpointsConstants.auth.base}${EndpointsConstants.auth.admin}${EndpointsConstants.auth.passwordUpdate}`;
    const params: any = {
      newPassword: password
    };
    return this.httpService.post(url, params, true, {headers: {authorization: token}});
  }

  setCurrentAdmin(admin: Admin) {
    setLocalData('balanceo-admin-currentAdmin', new Admin(admin));
  }

  getCurrentAdmin() {
    const admin = getLocalData('balanceo-admin-currentAdmin');
    return !isNull(admin) ? new Admin(admin) : null;
  }
}
