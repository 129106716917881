import { Routes } from '@angular/router';
// Private routes
export const PRIVATE_ROUTES: Routes = [
  {
    path: 'admin',
    loadChildren: () => import('../../modules/admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: 'balancing',
    loadChildren: () => import('../../modules/balancing/balancing.module').then(m => m.BalancingModule)
  },
  {
    path: 'client',
    loadChildren: () => import('../../modules/client/client.module').then(m => m.ClientModule)
  },
  {
    path: 'client-endpoint',
    loadChildren: () => import('../../modules/client-endpoint/client-endpoint.module').then(m => m.ClientEndpointModule)
  },
  {
    path: 'country',
    loadChildren: () => import('../../modules/country/country.module').then(m => m.CountryModule)
  },
  {
    path: 'province',
    loadChildren: () => import('../../modules/province/province.module').then(m => m.ProvinceModule)
  },
  {
    path: 'city',
    loadChildren: () => import('../../modules/city/city.module').then(m => m.CityModule)
  },
  {
    path: 'predictor',
    loadChildren: () => import('../../modules/predictor/predictor.module').then(m => m.PredictorModule)
  },
  {
    path: 'variable',
    loadChildren: () => import('../../modules/variable/variable.module').then(m => m.VariableModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('../../modules/setting/setting.module').then(m => m.SettingModule)
  }
];
