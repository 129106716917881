export enum CatalogResponsiveBreakpoints {
    desktop = 1200,
    laptop = 991,
    tablet = 768,
    smart = 576
}

export enum CatalogUploadTypeClass {
    'avatar' = 'avatar'
}

export const CatalogProfiles = [
    'super_admin'
];

export const CatalogClientEndpointTypes = {
    trips: {
        label: 'helpers.catalog.clientEndpointType.trips',
        key: 'TRIPS'
    },
    stations: {
        label: 'helpers.catalog.clientEndpointType.stations',
        key: 'STATIONS'
    }
};

export const CatalogPredictionPeriod = [
    {
        id: '1HOUR',
        label: 'Una hora'
    },
    {
        id: '3HOURS',
        label: 'Tres horas'
    }
];

export const CatalogTableBadgeColor = {
    green: 'green',
    orange: 'orange',
    blue: 'blue',
    grey: 'grey',
    greySecondary: 'greySecondary',
    cyan: 'cyan',
    red: 'red',
    yellow: 'yellow',
    deepPurple: 'deep-purple',
    darkRed: 'dark-red'
};

export const CatalogMapComponentData = {
    lat: -32.9391913,
    lng: -60.6633612,
    zoom: 15
};

export enum CatalogMarkerIcon {
    HUB = './assets/img/map/markers/hub.svg',
    TRANSPARENT = './assets/img/map/transparent_ico_.png'
}

export enum CatalogApiScopes {
    country = 'country',
    province = 'province',
    predictor = 'predictor',
    clients = 'clients'
}
