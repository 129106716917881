import { BaseEntity } from './base-entity';
import { getClassToCropImage } from '../helper/utils';
import { DefaultAvatarUrl } from '../helper/config';
​
export class Admin extends BaseEntity<Admin> {
  id: number;
  firstName: string;
  lastName: string;
  fullName: string;
  avatarUrl: string;
  avatarClassCropImage: string;
  email: string;
  phone: string;
  password: string;
  profiles: string;
​
  constructor(admin?) {
    super(admin);
  }
​
  parse(e: any): Admin {
    const admin = new Admin(e);
    return admin;
  }
​
  populate(admin) {
    this.id = admin.id;
    this.firstName = admin.firstName;
    this.lastName = admin.lastName;
    this.fullName = `${admin.firstName} ${admin.lastName}`;
    this.avatarUrl = admin.avatarUrl ? admin.avatarUrl : DefaultAvatarUrl;
    this.avatarClassCropImage = getClassToCropImage(this.avatarUrl);
    this.phone = admin.phone;
    this.email = admin.email;
    this.password = admin.password;
    this.profiles = (admin.profiles) ? admin.profiles : [];
  }
}
