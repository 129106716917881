import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-info-tooltip-component',
  templateUrl: './info-tooltip.component.html'
})
export class InfoTooltipComponent {
  @Input() size: string;
  @Input() icon: string;
}
