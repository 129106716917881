import { Component, OnInit, ChangeDetectionStrategy, ViewChild, ElementRef, Input, OnChanges, OnDestroy, EventEmitter, Output, SimpleChange } from '@angular/core';
import { MarkerService } from '../../services/marker/marker.service';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { InfoWindowService } from '../../services/info-window/info-window.service';
import { getDefaultMarker } from '../../models/map';

@Component({
  selector: 'app-map-marker',
  templateUrl: './map-marker.component.html',
  styleUrls: ['./map-marker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapMarkerComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('content', { static: false }) contentRef: ElementRef;

  @Input() lat: number;
  @Input() lng: number;
  @Input() title: string;
  @Input() label: string | google.maps.MarkerLabel;
  @Input() draggable = false;
  @Input() iconUrl?: string;
  @Input() visible = true;
  @Input() hasInfoWindow = false;
  @Input() opacity = 1;
  @Input() zIndex = 1;
  @Input() clickable = true;

  @Input() labelContent = getDefaultMarker('./assets/img/map/markers/hub.svg');
  @Input() labelAnchor = new google.maps.Point(14, 19);
  @Input() labelClass = 'labels';
  @Input() labelInBackground = true;

  private markerAdded = false;
  private readonly onDestroy$ = new EventEmitter<void>();

  constructor(
    private markerService: MarkerService,
    private infoWindowService: InfoWindowService
  ) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: {[key: string]: SimpleChange}) {
    if (!this.markerAdded) {
      this.markerService.addMarker(this);
      this.markerAdded = true;
      this.addEventListeners();
    }

    if (changes.iconUrl) {
      this.markerService.updateIcon(this);
    }

    if (changes.labelContent) {
      this.markerService.updateLabelContent(this);
    }

    if (changes.labelClass) {
      this.markerService.updateLabelClass(this);
    }

    if (changes.lat || changes.lng) {
        this.markerService.updateMarkerPosition(this);
    }
  }

  addEventListeners() {
    this.markerService.createEventObservable('click', this)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe( () => {
        if (this.hasInfoWindow) {
          this.openInfoWindow();
        }
      });
  }

  openInfoWindow() {
    this.infoWindowService.openInfoWindow({
      latitude: this.lat,
      longitude: this.lng,
      content: this.contentRef.nativeElement.outerHTML
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.markerService.deleteMarker(this);
  }
}
