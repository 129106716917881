import { Injectable } from '@angular/core';
import { MapService } from '../map/map.service';

@Injectable({
    providedIn: 'root'
})

export class InfoWindowService {
    infoWindow: Promise<google.maps.InfoWindow>;

    constructor(protected mapService: MapService) {}

    openInfoWindow(infoWindow: any) {
        this.destroy();
        this.infoWindow = this.mapService.createInfoWindow({
            position: {
                lat: infoWindow.latitude,
                lng: infoWindow.longitude
            },
            disableAutoPan: true,
            content: infoWindow.content
        });
    }

    destroy() {
        if (this.infoWindow) {
            this.infoWindow.then( e => {
                e.close();
            });
        }
    }
}
