import { PredictedStation } from 'src/app/models/predicted-station';
import { CatalogMarkerIcon } from 'src/app/helper/catalog';

export let google: any;

export interface MapOptions {
    center?: google.maps.LatLng | google.maps.LatLngLiteral;
    zoom?: number;
    minZoom?: number;
    maxZoom?: number;
    disableDoubleClickZoom?: boolean;
    scrollwheel?: boolean;
    draggable?: boolean;
    keyboardShortcuts?: boolean;
    zoomControl?: boolean;
    zoomControlOptions?: google.maps.ZoomControlOptions;
    streetViewControl?: boolean;
    mapTypeControl?: boolean;
    mapTypeControlOptions?: google.maps.MapTypeControlOptions;
    fullscreenControl?: boolean;
    fullscreenControlOptions?: google.maps.FullscreenControlOptions;
    mapTypeId?: google.maps.MapTypeId;
    clickableIcons?: boolean;
}

export interface MarkerOptions {
    position: google.maps.LatLng | google.maps.LatLngLiteral;
    title?: string;
    map?: google.maps.Map;
    label?: string | google.maps.MarkerLabel;
    draggable?: boolean;
    iconUrl?: string;
    opacity?: number;
    visible?: boolean;
    zIndex?: number;
    clickable?: boolean;
    animation?: any;
    labelContent?: string;
    labelAnchor?: any;
    labelClass?: string;
    labelInBackground?: boolean;
}

export interface MVCObject { addListener(eventName: string, handler: () => {}): MapsEventListener; }

export interface MapsEventListener { remove(): void; }

export class Marker implements MVCObject {
    constructor(options?: MarkerOptions) {
    }
    addListener(eventName: string, handler: any): MapsEventListener {
        throw new Error('Method not implemented');
    }
    set(element: string, content: any) {}
    setMap(map: google.maps.Map) {}
    setPosition(latLng: google.maps.LatLng | google.maps.LatLngLiteral) {}
    setTitle(title: string) {}
    setLabel(label: string | google.maps.MarkerLabel) {}
    setDraggable(draggable: boolean) {}
    setIcon(iconUrl: string) {}
    setOpacity(opacity: number) {}
    setVisible(visible: boolean) {}
    setZIndex(zIndex: number) {}
    setAnimation(animation: any) {}
    setClickable(clickable: boolean) {}
    setLabelAnchor(labelAnchor: any) {}
    setLabelClass(labelClass: string) {}
    setLabelContent(labelConten: string) {}
    setLabelInBackground(labelInBackground: boolean) {}
}

export function getDefaultMarker(iconUrl?: string) {
    return `<div class="map marker bg-red danger onequarter twoquarter threequarter"><img class="icon" src="${iconUrl}"/></div>`;
}

export function getPredictedStationMarker(predictedStation?: PredictedStation) {
    let classMarker = '';
    if (predictedStation.predictionOccupationIndex < 0) { classMarker = 'bg-dark-red dark-red'; }
    if (predictedStation.predictionOccupationIndex >= 0 && predictedStation.predictionOccupationIndex <= 0.2) { classMarker = 'bg-red danger'; }
    if (predictedStation.predictionOccupationIndex >= 0.21 && predictedStation.predictionOccupationIndex <= 0.39) { classMarker = 'bg-yellow warning onequarter'; }
    if (predictedStation.predictionOccupationIndex >= 0.40 && predictedStation.predictionOccupationIndex <= 0.60) { classMarker = 'bg-green success onequarter twoquarter'; }
    if (predictedStation.predictionOccupationIndex > 0.61 && predictedStation.predictionOccupationIndex <= 0.79) { classMarker = 'bg-yellow warning onequarter twoquarter threequarter'; }
    if (predictedStation.predictionOccupationIndex > 0.79 && predictedStation.predictionOccupationIndex <= 1) { classMarker = 'bg-red danger onequarter twoquarter threequarter fourquarter'; }
    if (predictedStation.predictionOccupationIndex > 1) { classMarker = 'bg-dark-red dark-red onequarter twoquarter threequarter fourquarter'; }

    return {
        ...predictedStation,
        iconUrl: CatalogMarkerIcon.TRANSPARENT,
        labelContent: `<div class="map marker ${classMarker}"><img class="icon" src="${CatalogMarkerIcon.HUB}" alt="marker icon"/></div>`,
    };
}
