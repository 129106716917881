import { Component, OnInit, ChangeDetectionStrategy, Input, ViewChild, AfterViewInit, SimpleChanges, OnChanges, EventEmitter, OnDestroy, Output, ChangeDetectorRef } from '@angular/core';
import { MapService } from '../../services/map/map.service';
import { isNullOrEmptyOrUndefined } from 'src/app/helper/utils';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('gmap', { static: false }) gmapElement: any;

  @Input() lat: number;
  @Input() lng: number;
  @Input() width: string;
  @Input() height: string;
  @Input() zoom = 15;
  @Input() draggable = false;
  @Input() fullscreenControl = false;
  @Input() scrollwheel = false;

  @Output() centerChange = new EventEmitter();
  @Output() zoomChange = new EventEmitter();

  map: google.maps.Map;
  private readonly onDestroy$ = new EventEmitter<void>();

  constructor(
    private mapService: MapService,
  ) {}

  ngOnInit() {
    this.mapService.init();
  }

  ngAfterViewInit() {
    const mapSettings = this.setMapSettings();
    this.mapService.createMap(this.gmapElement.nativeElement, mapSettings);
    this.addEventListeners();
  }

  setMapSettings() {
    return {
      center: new google.maps.LatLng(this.lat, this.lng),
      zoom: this.zoom,
      fullscreenControl: this.fullscreenControl,
      scrollwheel: this.scrollwheel,
      clickableIcons: false,
      streetViewControl: false
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    this.updatePosition(changes);
  }

  private updatePosition(changes: SimpleChanges) {
    if (changes.lat || changes.lng) {
      if (isNullOrEmptyOrUndefined(this.lat) && isNullOrEmptyOrUndefined(this.lng)) {
        return;
      }
      if (typeof this.lat !== 'number' || typeof this.lng !== 'number') {
        return;
      }
      this.setCenter();
    }
    if (changes.zoom && !changes.zoom.firstChange) {
      this.setZoom();
    }
  }

  setCenter() {
    const newCenter = {
      lat: this.lat,
      lng: this.lng
    };
    this.mapService.setCenter(newCenter);
  }

  setZoom() {
    this.mapService.setZoom(this.zoom);
  }

  addEventListeners() {
    this.mapService.createEventObservable<void>('center_changed')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe( () => {
        this.mapService.getCenter().then((center: google.maps.LatLng) => {
          this.lat = center.lat();
          this.lng = center.lng();
          this.centerChange.emit({ lat: this.lat, lng: this.lng } as google.maps.LatLngLiteral);
        });
      });

    this.mapService.createEventObservable<void>('zoom_changed')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe( () => {
        this.mapService.getZoom().then((z: number) => {
          this.zoom = z;
          this.zoomChange.emit(z);
        });
      });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }
}
