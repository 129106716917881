import { Injectable, ChangeDetectionStrategy } from '@angular/core';

import { Marker } from '../../models/map';

import { MapMarkerComponent } from 'src/app/shared/modules/map/components/map-marker/map-marker.component';
import { MapService } from '../map/map.service';
import { Observable } from 'rxjs/internal/Observable';
import { Observer } from 'rxjs/internal/types';

@Injectable({
    providedIn: 'root'
})

export class MarkerService {
    markers: Map<MapMarkerComponent, Promise<Marker>> = new Map<MapMarkerComponent, Promise<Marker>>();

    constructor(protected mapService: MapService) {}

    addMarker(marker: MapMarkerComponent) {
        const markerPromise = this.mapService.createMarker({
            position: { lat: marker.lat, lng: marker.lng },
            iconUrl: marker.iconUrl,
            opacity: marker.opacity,
            visible: true,
            draggable: marker.draggable,
            labelClass: marker.labelClass,
            labelContent: marker.labelContent,
            labelAnchor: marker.labelAnchor,
            labelInBackground: marker.labelInBackground
        });
        this.markers.set(marker, markerPromise);
    }

    updateMarkerPosition(marker: MapMarkerComponent): Promise<void> {
        return this.markers.get(marker).then( (m: Marker) => {
            m.setPosition({ lat: marker.lat, lng: marker.lng });
        });
    }

    updateIcon(marker: MapMarkerComponent): Promise<void> {
        return this.markers.get(marker).then( (m: Marker) => m.setIcon(marker.iconUrl));
    }

    updateLabelClass(marker: MapMarkerComponent): Promise<void> {
        return this.markers.get(marker).then( (m: Marker) => m.setLabelClass(marker.labelClass));
    }

    updateLabelContent(marker: MapMarkerComponent): Promise<void> {
        return this.markers.get(marker).then((m: Marker) => m.set('labelContent', marker.labelContent));
    }

    deleteMarker(marker: MapMarkerComponent): Promise<void> {
        const m = this.markers.get(marker);
        if (m === null) {
            // marker already deleted
            return Promise.resolve();
        }
        return m.then(( mark: Marker) => {
            mark.setMap(null);
            this.markers.delete(marker);
        });
    }

    createEventObservable<T>(eventName: string, marker: MapMarkerComponent): Observable<T> {
        return new Observable((observer: Observer<T>) => {
          this.markers.get(marker).then((m: Marker) => {
            m.addListener(eventName, (e: T) => observer.next(e) );
          });
        });
    }
}
