import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapMarkerComponent } from './components/map-marker/map-marker.component';
import { MapComponent } from './components/map/map.component';
import { MapInfoWindowComponent } from './components/map-info-window/map-info-window.component';
import { PredictedStationInfoWindowComponent } from './components/predicted-station-info-window/predicted-station-info-window.component';

@NgModule({
  declarations: [
    MapComponent,
    MapMarkerComponent,
    MapInfoWindowComponent,
    PredictedStationInfoWindowComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    MapComponent,
    MapMarkerComponent,
    MapInfoWindowComponent,
    PredictedStationInfoWindowComponent
  ]
})

export class MapModule { }
