import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { PredictedStation } from 'src/app/models/predicted-station';

@Component({
  selector: 'app-predicted-station-info-window',
  templateUrl: './predicted-station-info-window.component.html',
  styleUrls: ['./predicted-station-info-window.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PredictedStationInfoWindowComponent implements OnInit {

  @Input() predictedStation: PredictedStation;

  constructor() { }

  ngOnInit() {
  }

}
