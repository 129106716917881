import { Routes } from '@angular/router';

// Public routes
export const PUBLIC_ROUTES: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('../../modules/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'error',
    loadChildren: () => import('../../modules/error/error.module').then(m => m.ErrorModule)
  }
];
