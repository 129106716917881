import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-select-search',
  templateUrl: './select-search.component.html',
  styleUrls: ['./select-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectSearchComponent implements OnInit {
  public inputSearch$ = new Subject<string | null>();

  @Input() items: object[];
  @Input() bindValue: string;
  @Input() bindLabel: string;
  @Input() formGroup: FormGroup;
  @Input() controlName: string;
  @Input() multiple = false;
  @Input() placeholder = 'forms.messages.placeholder';
  @Output() changeItem = new EventEmitter();
  @Output() search = new EventEmitter();

  constructor() {
    this.subscribeToInputSearch();
  }

  ngOnInit() {
  }

  subscribeToInputSearch() {
    this.inputSearch$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    ).subscribe(term => {
      this.search.emit(term);
    });
  }

  onClearSelect() {
    this.search.emit('');
  }

  onChangeSelect(event) {
    this.changeItem.emit(event);
  }
}
