import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ROUTES } from './sidebar-routes.config';

declare var $: any;
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarComponent implements OnInit {
  public menuItems: object[];

  constructor() { }

  ngOnInit() {
    $.getScript('./assets/js/app-sidebar.js');
    this.getRoutes();
  }

  getRoutes() {
    const routes = JSON.parse(JSON.stringify(ROUTES));
    this.menuItems = [];
    routes.forEach( item => {
      const menu = {
        ...item
      };
      menu.submenu = [];
      // Validate roles in submenu
      item.submenu.forEach( subItem => {
        menu.submenu.push(subItem);
      });
      this.menuItems.push(menu);
    });
  }

}
